import { createAction } from 'typesafe-actions';
import { CustomData, EditQuantityData, RankData } from './types';

export const addCustomData = createAction('custom/ADD_CUSTOM_DATA')<{
  item: CustomData;
  isBulk: boolean;
}>();

export const removeCustomData = createAction('custom/REMOVE_CUSTOM_DATA')<number>();

export const editQuantity = createAction('custom/EDIT_QUANTITY')<EditQuantityData>();

export const removeAllData = createAction('custom/REMOVE_ALL_DATA')();

export const addRankData = createAction('custom/ADD_RANK_DATA')<RankData>();

export const removeRankData = createAction('custom/REMOVE_RANK_DATA')();

export const updateRankData = createAction('custom/UPDATE_RANK_DATA')<{
  type: string;
  index: number;
  value: number;
}>();
