import { RankInfo } from '../types';

export const maxProgress = {
  glory: 5500,
  infamy: 15000,
  valor: 2000
};

export const levels = ['guardian', 'brave', 'heroic', 'fabled', 'mythic', 'legend'];

export const glory: RankInfo = {
  maxProgress: 5500,
  ranks: [
    {
      name: 'Guardian I',
      level: 'guardian',
      from: 0,
      to: 39,
      pointsToNextRank: 40
    },
    {
      name: 'Guardian II',
      level: 'guardian',
      from: 40,
      to: 109,
      pointsToNextRank: 70
    },
    {
      name: 'Guardian III',
      level: 'guardian',
      from: 110,
      to: 199,
      pointsToNextRank: 90
    },
    {
      name: 'Brave I',
      level: 'brave',
      from: 200,
      to: 369,
      pointsToNextRank: 170
    },
    {
      name: 'Brave II',
      level: 'brave',
      from: 370,
      to: 664,
      pointsToNextRank: 295
    },
    {
      name: 'Brave III',
      level: 'brave',
      from: 665,
      to: 1049,
      pointsToNextRank: 385
    },
    {
      name: 'Heroic I',
      level: 'heroic',
      from: 1050,
      to: 1259,
      pointsToNextRank: 210
    },
    {
      name: 'Heroic II',
      level: 'heroic',
      from: 1260,
      to: 1624,
      pointsToNextRank: 365
    },
    {
      name: 'Heroic III',
      level: 'heroic',
      from: 1625,
      to: 2099,
      pointsToNextRank: 475
    },
    {
      name: 'Fabled I',
      level: 'fabled',
      from: 2100,
      to: 2379,
      pointsToNextRank: 280
    },
    {
      name: 'Fabled II',
      level: 'fabled',
      from: 2380,
      to: 2969,
      pointsToNextRank: 490
    },
    {
      name: 'Fabled III',
      level: 'fabled',
      from: 2870,
      to: 3499,
      pointsToNextRank: 630
    },
    {
      name: 'Mythic I',
      level: 'mythic',
      from: 3500,
      to: 3879,
      pointsToNextRank: 380
    },
    {
      name: 'Mythic II',
      level: 'mythic',
      from: 3880,
      to: 4544,
      pointsToNextRank: 665
    },
    {
      name: 'Mythic III',
      level: 'mythic',
      from: 4545,
      to: 5449,
      pointsToNextRank: 905
    },
    {
      name: 'Legend',
      level: 'legend',
      from: 5450,
      to: 5500,
      pointsToNextRank: 50
    }
  ]
};

export const valor: RankInfo = {
  maxProgress: 2000,
  ranks: [
    {
      name: 'Guardian I',
      level: 'guardian',
      from: 0,
      to: 9,
      pointsToNextRank: 10
    },
    {
      name: 'Guardian II',
      level: 'guardian',
      from: 10,
      to: 24,
      pointsToNextRank: 15
    },
    {
      name: 'Guardian III',
      level: 'guardian',
      from: 25,
      to: 49,
      pointsToNextRank: 25
    },
    {
      name: 'Brave I',
      level: 'brave',
      from: 50,
      to: 109,
      pointsToNextRank: 60
    },
    {
      name: 'Brave II',
      level: 'brave',
      from: 110,
      to: 214,
      pointsToNextRank: 105
    },
    {
      name: 'Brave III',
      level: 'brave',
      from: 215,
      to: 349,
      pointsToNextRank: 135
    },
    {
      name: 'Heroic I',
      level: 'heroic',
      from: 350,
      to: 419,
      pointsToNextRank: 70
    },
    {
      name: 'Heroic II',
      level: 'heroic',
      from: 420,
      to: 549,
      pointsToNextRank: 130
    },
    {
      name: 'Heroic III',
      level: 'heroic',
      from: 550,
      to: 699,
      pointsToNextRank: 150
    },
    {
      name: 'Fabled I',
      level: 'fabled',
      from: 700,
      to: 789,
      pointsToNextRank: 90
    },
    {
      name: 'Fabled II',
      level: 'fabled',
      from: 790,
      to: 939,
      pointsToNextRank: 150
    },
    {
      name: 'Fabled III',
      level: 'fabled',
      from: 940,
      to: 1149,
      pointsToNextRank: 210
    },
    {
      name: 'Mythic I',
      level: 'mythic',
      from: 1150,
      to: 1279,
      pointsToNextRank: 130
    },
    {
      name: 'Mythic II',
      level: 'mythic',
      from: 1280,
      to: 1509,
      pointsToNextRank: 230
    },
    {
      name: 'Mythic III',
      level: 'mythic',
      from: 1510,
      to: 1799,
      pointsToNextRank: 290
    },
    {
      name: 'Legend',
      level: 'legend',
      from: 1800,
      to: 2000,
      pointsToNextRank: 200
    }
  ]
};

export const infamy: RankInfo = {
  maxProgress: 15000,
  ranks: [
    {
      name: 'Guardian I',
      level: 'guardian',
      from: 0,
      to: 249,
      pointsToNextRank: 250
    },
    {
      name: 'Guardian II',
      level: 'guardian',
      from: 250,
      to: 599,
      pointsToNextRank: 350
    },
    {
      name: 'Guardian III',
      level: 'guardian',
      from: 600,
      to: 999,
      pointsToNextRank: 400
    },
    {
      name: 'Brave I',
      level: 'brave',
      from: 1000,
      to: 1449,
      pointsToNextRank: 450
    },
    {
      name: 'Brave II',
      level: 'brave',
      from: 1450,
      to: 1949,
      pointsToNextRank: 500
    },
    {
      name: 'Brave III',
      level: 'brave',
      from: 1950,
      to: 2499,
      pointsToNextRank: 550
    },
    {
      name: 'Heroic I',
      level: 'heroic',
      from: 2500,
      to: 3099,
      pointsToNextRank: 600
    },
    {
      name: 'Heroic II',
      level: 'heroic',
      from: 3100,
      to: 3749,
      pointsToNextRank: 650
    },
    {
      name: 'Heroic III',
      level: 'heroic',
      from: 3750,
      to: 4499,
      pointsToNextRank: 750
    },
    {
      name: 'Fabled I',
      level: 'fabled',
      from: 4500,
      to: 5349,
      pointsToNextRank: 850
    },
    {
      name: 'Fabled II',
      level: 'fabled',
      from: 5350,
      to: 6349,
      pointsToNextRank: 1000
    },
    {
      name: 'Fabled III',
      level: 'fabled',
      from: 6350,
      to: 7499,
      pointsToNextRank: 1150
    },
    {
      name: 'Mythic I',
      level: 'mythic',
      from: 7500,
      to: 8799,
      pointsToNextRank: 1300
    },
    {
      name: 'Mythic II',
      level: 'mythic',
      from: 8800,
      to: 10299,
      pointsToNextRank: 1500
    },
    {
      name: 'Mythic III',
      level: 'mythic',
      from: 10300,
      to: 11999,
      pointsToNextRank: 1700
    },
    {
      name: 'Legend',
      level: 'legend',
      from: 12000,
      to: 15000,
      pointsToNextRank: 3000
    }
  ]
};
